.ForgotPasswordScreen{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7eff1a3;

    .link-text{
      color: #b21336;
      font-weight: 500;
      font-size: 14px;
      margin-top: 0.5rem;
      cursor: pointer;
      text-decoration: none;
    }
  
    .loginBox{
      border-radius: 8px;
      border: 1px solid #dadce0;
      height: auto;
      min-height: 20rem;
      padding: 2rem 2rem;
      min-width: 25rem;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      position: relative;
  
      .logo{
        display: flex;
        align-items: center;
        justify-content: center;
  
        img{
          width: 8rem;
        }
      }
  
      .header{
        text-align: center;
        padding: 1.5rem 0rem 0rem 0rem;
        font-size: 1.5rem;
      }
  
      .emailBox{
        padding: 0.5rem;
        font-size: 1rem;
        display: flex;
        border: 1px solid #dadce0;
        align-items: center;
        justify-content: space-evenly;
        max-width: 16rem;
        border-radius: 5rem;
        margin: auto;
        min-width: 16rem;
        margin-top: 1rem;
        cursor: pointer;

        span{
            font-weight: 500;
        }
      }
  
      .formControl{
        display: flex;
        padding: 1.5rem 0rem;
        flex-direction: column;

        label{
          margin-bottom: 0.5rem;
          font-weight: 600;
          font-size: 15px;
        }

        .otp-input-root{
          justify-content: space-between;

          input{
            border: 1px solid #dadce0;
            outline: 0;
          }
          
          input:last-child{
            margin-right:0 !important;
          }
        }

        #google-signin2{
            margin: 0rem 0 0 0;
            .abcRioButton{
                min-width: 70%;
                margin: auto;
                min-height: 3rem;
            }
        }
      }
  
      .btnContainer{
        display: flex;
        padding: 1rem 0rem;
        flex-direction: row-reverse;
      }
  
    }
  }