.loader {
  height: 4px;
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.loader.active:before{
  display: block;
}
.loader:before{
  display: none;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #DA2A52;
  animation: loading 2s linear infinite;
}

@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}