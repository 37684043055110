.DashboardScreen{
    .MainScreen{
        padding: 1rem 4rem;
        display: flex;
        flex-direction: column;

        .title{
            font-size: 1.3rem;
            font-weight: 500;
            margin: 1.5rem 0;
        }

        .productContainer{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .productCard{
                display: flex;
                flex-direction: column;
                max-width: 40rem;
                border: 1px solid #dedede;
                border-radius: 5px;
                margin-bottom: 2rem;

                .cardBody{
                    display: flex;
                    padding: 2rem 2rem;
                    .left{
                        .productTitle{
                            font-size: 1.2rem;
                            font-weight: 500;
                            margin-bottom: 1rem;
                        }

                        .productDescription{
                            padding-right: 3rem;
                            color: #3a3636;
                        }
                    }

                    .right{
                        width: 25rem;
                        img{
                            width: 100%;
                        }
                    }
                    


                }

                .cardFooter{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5rem 0rem;
                    border-top: 1px solid #dedede;
                    button{
                        border-radius: 50px;
                        background-image: none;
                        background-color: #fff;
                        border: 1px solid #DEDEDE;
                        color: #505050;
                        font-weight: 400;
                        min-width: 10rem;
                    }
                }
            }
        }
    }
}