.ImpersonateScreen{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7eff1a3;

  .loginBox{
    border-radius: 8px;
    border: 1px solid #dadce0;
    height: auto;
    min-height: 20rem;
    padding: 2rem 2rem;
    min-width: 25rem;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    position: relative;

    .logo{
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 8rem;
      }
    }

    .header{
      text-align: center;
      padding: 1.5rem 0rem 0rem 0rem;
      font-size: 1rem;
    }

    .logout-link{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      font-size: 0.8rem;
      font-weight: 600;
      color: #b10f36;
      margin-top: -0.6rem;
      span{
        cursor: pointer;
      }
    }

    .emailBox{
      padding: 0.5rem;
      font-size: 1rem;
      display: flex;
      border: 1px solid #dadce0;
      align-items: center;
      justify-content: space-evenly;
      max-width: 16rem;
      border-radius: 5rem;
      margin: auto;
      min-width: 16rem;
      margin-top: 1rem;
      cursor: pointer;
      margin-bottom: 1rem;

      span{
          font-weight: 500;
      }
    }

    ul.renderSignedInEmailsContainer{
      margin: 0;
      padding: 0;
      list-style: none;
      max-height: 9rem;
      overflow: auto;

      li{
        border: 1px solid #d9dce0;
        min-height: 2.5rem;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: #ffffff;
        margin-bottom: 0.5rem;
        position: relative;
        i{
          font-size: 1.3rem;
          margin-right: 0.5rem;
          color: #717171;
          margin-top: 0.1rem;
        }

      }

      li:last-child:after{
        content: "OR";
        position: absolute;
        margin-left: 41%;
        bottom: -1.7rem;
        font-weight: 600;
        color: #db2a53;
      }
    }

    .formControl{
      display: flex;
      padding: 1.5rem 0rem;
      flex-direction: column;

      #google-signin2{
          margin: 0rem 0 0 0;
          .abcRioButton{
              min-width: 70%;
              margin: auto;
              min-height: 3rem;
          }
      }
    }

    .btnContainer{
      display: flex;
      padding: 1rem 0rem;
      justify-content: space-between;
    }

    .btnLabel{
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      margin-top: -0.9rem;
      color: #585858;
    }

  }
}