.Header{
    display: flex;
    justify-content: space-between;
    height: 4rem;
    align-items: center;
    padding: 1rem 4rem;

    .logo{
        width: 12rem;
        display: flex;
        height: 100%;
        align-items: center;
        img{
            height: 80%;
        }
    }

    .dropdownContainer{
        display: flex;
        position: relative;
        button{
            border-radius: 50px;
            background-image: none;
            background-color: #fff;
            border: 1px solid #DEDEDE;
            color: #505050;
            font-weight: 400;
            i{
                margin-right: 0.5rem;
            }

            span{

            }
        }

        .dropdown-backdrop{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }

        ul.dropdown{
            position: absolute;
            margin: 0;
            list-style: none;
            background: #fff;
            border: 1px solid #ccc;
            border-color: rgba(0, 0, 0, 0.2);
            color: #000;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            display: block;
            outline: none;
            overflow: hidden;
            right: 0;
            top: 2.5rem;
            animation-duration: 0.2s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
            border-radius: 2px;
            min-height: 5rem;
            min-width: 20rem;
            z-index: 3;
            padding: 1rem 1rem;
            li{
                display: flex;
                align-items: center;
                justify-content: center;
                .impersonatedAs, .loggedInAs{
                    display: flex;
                    flex-direction: column;
                    width: 85%;
                    padding: 1rem 0;
                    border-bottom: 1px solid #e2e2e2;
                    align-items: center;
                    .title{
                        font-weight: 500;
                        color: #504d4e;
                    }

                    .sub-title{
                        font-size: 14px;
                        font-weight: 500;
                        color: #504d4e;
                        margin: 0.2rem 0;
                        margin-bottom: 1rem;
                    }

                    button{
                        border-color: #df2b5600;
                        border-radius: 5px;
                        font-size: 13px;
                        min-width: 6rem;
                        outline: none !important;
                        letter-spacing: 1px;
                        font-weight: 600;
                        cursor: pointer;
                        text-align: center;
                        padding: 0.7rem 0;
                        text-transform: uppercase;
                        min-width: 100%;
                    }

                    button.white-btn{
                        background-color: #fff;
                        border: 1px solid #e2e2e2;
                    }

                    button.red-btn{
                        border-color: #df2b5600;
                        background-image: linear-gradient(to right, #E52F59, #AB0B32);
                        color: #ffffff;
                    }
                }

                .loggedInAs{
                    border:0;
                }
            }
        }
    }
}