button{
  border-color: #df2b5600;
  border-radius: 5px;
  background-image: linear-gradient(to right, #E52F59, #AB0B32);
  font-size: 16px;
  color: #ffffff;
  min-width: 6rem;
  height: 40px;
  outline: none !important;
  padding: 0rem 1rem;
  letter-spacing: 1px;
  font-weight: 900;
  cursor: pointer;
}

button:disabled{
  cursor:not-allowed;
  opacity: 0.5;
}