input[type=text],input[type=email],input[type=password],input[type=number]{
  min-height: 2.5rem;
  padding: 0 1rem;
  flex: 1;
  font-size: 1rem;
  border: 1px solid #dadce0;
  outline: 0;
}

input[type=text].error,input[type=email].error,input[type=password].error,input[type=number].error{
  border: 1px solid #DA2A52;
}