.IdentifierScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7eff1a3;

  .loginBox {
    border-radius: 8px;
    border: 1px solid #dadce0;
    height: auto;
    min-height: 20rem;
    padding: 2rem 2rem;
    min-width: 25rem;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    position: relative;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 8rem;
      }
    }

    .header {
      text-align: center;
      padding: 1.5rem 0rem 0rem 0rem;
      font-size: 1.5rem;
    }

    .sourceUrlName {
      text-align: center;
      padding: 0.5rem 0rem 1.5rem 0rem;
      font-size: 1rem;
    }

    ul.renderSignedInEmailsContainer {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-top: 1rem;
      max-height: 9rem;
      overflow: auto;

      li {
        border: 1px solid #d9dce0;
        min-height: 2.5rem;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: #ffffff;
        margin-bottom: 0.5rem;
        position: relative;

        i {
          font-size: 1.3rem;
          margin-right: 0.5rem;
          color: #717171;
          margin-top: 0.1rem;
        }
      }

      li:last-child:after {
        content: "OR";
        position: absolute;
        margin-left: 41%;
        bottom: -1.7rem;
        font-weight: 600;
        color: #db2a53;
      }
    }

    .formControl {
      display: flex;
      padding: 1.5rem 0rem;
    }

    .btnContainer {
      display: flex;
      padding: 1rem 0rem;
      flex-direction: row-reverse;
    }

    .btnLabel {
      display: flex;
      justify-content: flex-end;
      font-size: 11px;
      margin-top: -0.9rem;
      color: #585858;
    }

    //for helper button
    .helperButton {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 18px;
      span {
        font-size: 13px;
        font-weight: 500;
        color: #403c3c;
      }
      img {
        height: 18px;
        margin-left: 6px;
      }
    }
  }
}
